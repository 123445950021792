//@ts-nocheck
import React, { Component } from "react";
import {
  Grid,
  Button,
  Typography,
  Card,
  Theme,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  CircularProgress,
  Box,
  Avatar,
  IconButton,
  Drawer,
  Divider,
  TextField,
  Backdrop,
  Menu,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  ListItemIcon,
  ListItemText,
  Checkbox,
  InputAdornment,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import { default as ReactSelect } from "react-select";
import { components } from "react-select";
import { withStyles } from "@material-ui/styles";
import ReactTooltip from "react-tooltip";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import UserProfileBasicBlockController, {
  Props,
} from "./UserProfileBasicController";
import "./UserManagement.web.css";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
  getContentAnchorEl: null,
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "center",
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "center",
  },
  variant: "menu",
};

const Option = (props: any) => {
  return (
    <div>
      <components.Option {...props}>
        <input
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
        />{" "}
        <label>{props.label}</label>
      </components.Option>
    </div>
  );
};

const customStyles = {
  multiValueLabel: (base: any) => ({
    ...base,
    fontFamily: "Poppins",
  }),
  menuList: (base: any) => ({
    ...base,
    fontFamily: "Poppins",
  }),
};
const customStylesWithError = {
  multiValueLabel: (base: any) => ({
    ...base,
    fontFamily: "Poppins",
  }),
  menuList: (base: any) => ({
    ...base,
    fontFamily: "Poppins",
  }),
  control: (base: any) => ({
    ...base,
    boxShadow: "none",
    borderColor: "red",
    "&:hover": {
      borderColor: "red",
    },
    // You can also use state.isFocused to conditionally style based on the focus state
  }),
};

const actionDots = require("../assets/3dots.png");
const trashIcon = require("../assets/trash.png");
const editIcon = require("../assets/edit.png");
export class UserManagement extends UserProfileBasicBlockController {
  constructor(props: Props) {
    super(props);
    //Customizable Area Start
    //Customizable Area End
  }
  render() {
    const { navigation, classes } = this.props;
    //Customizable Area Start
    return (
      <>
        <Backdrop className={classes.backdrop} open={this.state.loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <ToastContainer />
        <Card className={classes.mainCard}>
          <Grid container spacing={2} style={{ padding: "20px" }}>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={10}>
                  <Typography variant="body2" className={classes.text1}>
                    User Management
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <Button
                    data-test-id="createUser"
                    variant="contained"
                    className={classes.exportBtn}
                    onClick={this.handleDrawer}
                    disabled={!this.state.canCreateUser}
                  >
                    <Typography variant="body2" className={classes.text3}>
                      Create User
                    </Typography>
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <TableContainer>
                <Table className={classes.table} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <Typography
                          variant="body2"
                          className={classes.tableHeads}
                        >
                          User Name
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          variant="body2"
                          className={classes.tableHeads}
                        >
                          Email ID
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          variant="body2"
                          className={classes.tableHeads}
                        >
                          Contact
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          variant="body2"
                          className={classes.tableHeads}
                        >
                          Role
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          variant="body2"
                          className={classes.tableHeads}
                        >
                          IMEI
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          variant="body2"
                          className={classes.tableHeads}
                        >
                          Device ID
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          variant="body2"
                          className={classes.tableHeads}
                        >
                          Action
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.userData.length !== 0 
                      ? this.state.userData.map((_data: any, index:any) => (
                        <React.Fragment key={index}>
                          <TableRow>
                            <TableCell className={classes.tableDatas}>
                              {_data.userName}
                            </TableCell>

                            <TableCell className={classes.tableDatas}>
                              {_data.email}
                            </TableCell>
                            <TableCell className={classes.tableDatas}>
                              {`+${_data.contact}`}
                            </TableCell>
                            <TableCell className={classes.tableDatas}>
                              {_data.role}
                            </TableCell>
                            <TableCell className={classes.tableDatas}>
                              <span style={{ paddingLeft: "5px" }}>
                                {
                                // _data.devices? 
                                  _data.devices.length !== 0
                                    ? `${_data.devices[0].attributes
                                      .imei_number
                                    }`
                                    : "NA"
                                  // : "NA"
                                }
                              </span>
                              <div
                                data-test-id="currentTarget"
                                onClick={(event: any) => {
                                  this.setState({
                                    anchorEl: event.currentTarget,
                                  });
                                }}
                                style={{
                                  display: "inline",
                                  paddingLeft: "10px",
                                  cursor: "pointer",
                                }}
                              >
                                <a data-tip data-for="imei">
                                  {_data.devices
                                    ? _data.devices.length !== 0
                                      ? `+${_data.devices.length - 1}`
                                      : ""
                                    : ""}
                                </a>
                              </div>
                              <ReactTooltip
                                id="imei"
                                aria-haspopup="true"
                                className="custom-color"
                                backgroundColor="#FFFFFF"
                                effect="solid"
                              >
                                {_data.devices.length !== 0
                                  ? _data.devices.map(
                                    (_devices: any, idx: any) => {
                                      if (idx !== 0) {
                                        return (
                                          <Grid container key={idx}>
                                            <Grid
                                              item
                                              xs={12}
                                              style={{
                                                justifyContent: "center",
                                                display: "flex",
                                                textAlign: "center",
                                              }}
                                            >
                                              <Typography
                                                variant="body2"
                                                className={classes.text6}
                                              >
                                                {
                                                  _devices.attributes
                                                    .imei_number
                                                }
                                              </Typography>
                                            </Grid>
                                          </Grid>
                                        );
                                      }
                                    }
                                  )
                                  : null}
                              </ReactTooltip>
                            </TableCell>
                            <TableCell
                              align="justify"
                              className={classes.tableDatas}
                            >
                              <span style={{ paddingLeft: "5px" }}>
                                {_data.devices
                                  ? _data.devices.length !== 0
                                    ? `${_data.devices[0].attributes.sid}`
                                    : ""
                                  : ""}
                              </span>
                              <div
                                id="handleDeviceMoreClickBtn"
                                onClick={(event) => {
                                  this.handleDeviceMoreClick(
                                    event,
                                    _data.devices
                                  );
                                }}
                                style={{
                                  display: "inline",
                                  paddingLeft: "10px",
                                  cursor: "pointer",
                                }}
                              >
                                <span className={classes.text7}>
                                  <a data-tip data-for="deviceid">
                                    {_data.devices
                                      ? _data.devices.length !== 0
                                        ? `+${_data.devices.length - 1}`
                                        : "NA"
                                      : "NA"}
                                  </a>
                                </span>
                              </div>
                              <ReactTooltip
                                id="deviceid"
                                aria-haspopup="true"
                                className="custom-color"
                                backgroundColor="#FFFFFF"
                                effect="solid"
                              >
                                <Grid container>
                                  {_data.devices.length !== 0
                                    ? _data.devices.map(
                                      (_devices: any, idx: any) => {
                                        if (idx !== 0) {
                                          return (
                                            <Grid
                                              key={idx}
                                              item
                                              xs={12}
                                              style={{
                                                justifyContent: "center",
                                                display: "flex",
                                                textAlign: "center",
                                              }}
                                            >
                                              <Typography
                                                variant="body2"
                                                className={classes.text6}
                                              >
                                                {_devices.attributes.sid}
                                              </Typography>
                                            </Grid>
                                          );
                                        }
                                      }
                                    )
                                    : null}
                                </Grid>
                              </ReactTooltip>
                            </TableCell>
                            <TableCell>
                              <IconButton id="handleOpenMenu" onClick={(e) => {
                                    this.handleOpenAboutMenu(e, _data);
                                  }}>
                                <img
                                  src={actionDots}
                                  // onClick={(e) => {
                                  //   this.handleOpenAboutMenu(e, _data);
                                  // }}
                                />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        </React.Fragment>
                      ))
                      : ""}
                    <Menu
                      id="simple-menu"
                      anchorEl={this.state.anchorEl3}
                      keepMounted
                      open={Boolean(this.state.anchorEl3)}
                      style={{ marginTop: "40px" }}
                      onClose={this.handleAboutMenuClose}
                    >
                      <MenuItem id="userEdit" onClick={this.handleUserEdit}>Edit</MenuItem>
                      <MenuItem id="userDelete" onClick={this.handleUserDelete}>
                        Delete
                      </MenuItem>
                    </Menu>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            {/* <Grid
              item
              xs={12}
              style={{ display: "flex", justifyContent: "center" }}
            >
              <Button variant="contained" className={classes.loadMoreBtn}>
                <Typography variant="body2" className={classes.loadMoreBtnTxt}>
                  Load More
                </Typography>
              </Button>
            </Grid> */}
          </Grid>
        </Card>

        <Drawer
          anchor="right"
          open={this.state.drawer}
          onClose={this.handleDrawer}
        >
          <Grid container spacing={2} style={{ width: "300px" }}>
            <Grid item xs={12}>
              <Grid container style={{ padding: "20px" }}>
                <Grid item xs={9}>
                  <Typography variant="body2" className={classes.text1}>
                    Create User
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <Button
                    variant="contained"
                    className={classes.btnUserCancel}
                    onClick={this.handleDrawer}
                  >
                    <span style={{ color: "#FFFFFF" }}>x</span>
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2} style={{ padding: "20px" }}>
                <Grid item xs={12}>
                  <Typography variant="body2" className={classes.text4}>
                    First Name
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="txtFirstName"
                    fullWidth
                    variant="outlined"
                    value={this.state.firstName}
                    onChange={this.handleFirstName}
                    error={!this.state.isFirstNameValid ? true : false}
                    helperText={
                      <span style={{ fontSize: "12px", fontFamily: "Poppins" }}>
                        {this.state.firstNameErrorMessage}
                      </span>
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body2" className={classes.text4}>
                    Last Name
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    value={this.state.lastName}
                    onChange={this.handleLastName}
                    error={!this.state.isLastNameValid ? true : false}
                    helperText={
                      <span style={{ fontSize: "12px", fontFamily: "Poppins" }}>
                        {this.state.lastNameErrorMessage}
                      </span>
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body2" className={classes.text4}>
                    Email ID
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    value={this.state.emailId}
                    onChange={this.handleEmail}
                    error={!this.state.isEmailValid ? true : false}
                    helperText={
                      <span style={{ fontSize: "12px", fontFamily: "Poppins" }}>
                        {this.state.emailErrorMessage}
                      </span>
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body2" className={classes.text4}>
                    Contact
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    value={this.state.contact}
                    onChange={this.handleContact}
                    // InputProps={{
                    //   startAdornment: (
                    //     <InputAdornment position="start">+91</InputAdornment>
                    //   ),
                    // }}
                    error={!this.state.isContactValid ? true : false}
                    helperText={
                      <span style={{ fontSize: "12px", fontFamily: "Poppins" }}>
                        {this.state.contactErrorMessage}
                      </span>
                    }
                  />
                  <small className={classes.text4}>
                    Enter phone number with country code (Ex. 91XXXXXXXXXX)
                  </small>
                </Grid>
                {/* <Grid item xs={12}>
                  <Typography variant="body2" className={classes.text4}>
                    Role
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextField fullWidth variant="outlined" />
                </Grid> */}
                <Grid item xs={12}>
                  <Typography variant="body2" className={classes.text4}>
                    Devices
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <ReactSelect
                    id="devices"
                    options={this.state.deviceList}
                    hideSelectedOptions={false}
                    onChange={this.handleDeviceChange}
                    value={this.state.selectedDevice}
                    styles={customStyles}
                    placeholder={
                      <span className={classes.text2}>Select Devices</span>
                    }
                  />
                  {/* {!this.state.isDeviceValid ? (
                    <span
                      style={{
                        fontSize: "12px",
                        fontFamily: "Poppins",
                        color: "red",
                        paddingLeft: "15px",
                      }}
                    >
                      {this.state.deviceErrorMessage}
                    </span>
                  ) : (
                    ""
                  )} */}
                </Grid>

                <Grid item xs={12}>
                  <Typography variant="body2" className={classes.text4}>
                    Sub Devices
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <ReactSelect
                    options={this.state.subDeviceList}
                    isMulti
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    components={{
                      Option,
                    }}
                    onChange={this.handleSubDeviceChange}
                    allowSelectAll={true}
                    value={this.state.selectedSubDevice}
                    styles={
                      this.state.isDeviceValid
                        ? customStyles
                        : customStylesWithError
                    }
                    placeholder={
                      <span className={classes.text2}>Select Sub Device</span>
                    }
                  />
                  {!this.state.isDeviceValid ? (
                    <span
                      style={{
                        fontSize: "12px",
                        fontFamily: "Poppins",
                        color: "red",
                        paddingLeft: "15px",
                      }}
                    >
                      {this.state.deviceErrorMessage}
                    </span>
                  ) : (
                    ""
                  )}
                </Grid>

                <Grid item xs={12}>
                  <Button
                    id="btnCheckFormDetails"
                    variant="outlined"
                    className={classes.DrawerBtnCreateUser}
                    fullWidth
                    onClick={this.checkFormDetails}
                  >
                    <Typography variant="body2" className={classes.text5}>
                      {this.state.isEdit ? "Update User" : "Create User"}
                    </Typography>
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Drawer>

        {/* Delete Dialog Start */}
        <Dialog
          open={this.state.deleteDialogFlag}
          onClose={this.handleCloseDeleteDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent style={{ width: "400px", height: "300px" }}>
            <Grid
              container
              direction="column"
              alignItems="center"
              justify="center"
            >
              <Grid item xs={12} style={{ minHeight: "120px" }}>
                <img
                  src={trashIcon}
                  alt="trash"
                  style={{ paddingTop: "25px" }}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body2" className={classes.text8}>
                  Are you sure?
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                style={{ textAlign: "center", paddingTop: "10px" }}
              >
                <Typography variant="body2" className={classes.text11}>
                  This action cannot be undone once it is performed
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              alignItems="center"
              style={{ paddingLeft: "10px", paddingTop: "20px" }}
            >
              <Grid item xs={2} />
              <Grid item xs={4}>
                <Button
                  id="closeDeleteDialog"
                  variant="contained"
                  className={classes.deleteCancelBtn}
                  onClick={this.handleCloseDeleteDialog}
                >
                  <Typography variant="body2" className={classes.text9}>
                    Cancel
                  </Typography>
                </Button>
              </Grid>
              <Grid item xs={4}>
                <Button
                  id="deleteUser"
                  variant="contained"
                  onClick={this.deleteUser}
                  className={classes.deleteDeleteBtn}
                >
                  <Typography variant="body2" className={classes.text10}>
                    Delete
                  </Typography>
                </Button>
              </Grid>
              <Grid item xs={2} />
            </Grid>
          </DialogContent>
        </Dialog>
        {/* Delete Dialog End */}

        {/* Edit Dialog Start */}
        <Dialog
          open={this.state.editDialogFlag}
          onClose={this.handleCloseEditDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent style={{ width: "400px", height: "300px" }}>
            <Grid
              container
              direction="column"
              alignItems="center"
              justify="center"
            >
              <Grid item xs={12} style={{ minHeight: "120px" }}>
                <img
                  src={editIcon}
                  alt="trash"
                  style={{ paddingTop: "25px" }}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body2" className={classes.text8}>
                  Are you sure?
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                style={{ textAlign: "center", paddingTop: "10px" }}
              >
                <Typography variant="body2" className={classes.text11}>
                  This will edit your existing data
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              alignItems="center"
              style={{ paddingLeft: "10px", paddingTop: "20px" }}
            >
              <Grid item xs={2} />
              <Grid item xs={4}>
                <Button
                  id="closeEditDialog"
                  variant="contained"
                  className={classes.deleteCancelBtn}
                  onClick={this.handleCloseEditDialog}
                >
                  <Typography variant="body2" className={classes.text9}>
                    Cancel
                  </Typography>
                </Button>
              </Grid>
              <Grid item xs={4}>
                <Button
                  id="confirmEditUser"
                  variant="contained"
                  className={classes.editConfirmBtn}
                  onClick={this.confirmEditUser}
                >
                  <Typography variant="body2" className={classes.text10}>
                    Confirm
                  </Typography>
                </Button>
              </Grid>
              <Grid item xs={2} />
            </Grid>
          </DialogContent>
        </Dialog>
        {/* Edit Dialog End */}
      </>
    );
    //Customizable Area End
  }
}

// Customizable Area Start
const styles = (theme: Theme) => ({
  // mainCard: {
  //   boxShadow: "10px 15px 15px #eaeaea, -10px -9px 15px #eaeaea",
  // },
  text1: {
    opacity: 1,
    fontWeight: 500,
    color: "rgba(17, 20, 45, 1)",
    fontFamily: "Poppins",
    fontSize: "25px",
  },
  text2: {
    opacity: 1,
    fontStyle: "normal",
    fontWeight: 500,
    color: "rgba(128, 129, 145, 1)",
    fontFamily: "Poppins",
    fontSize: "13px",
  },
  text3: {
    opacity: 1,
    fontWeight: 500,
    color: "rgba(255, 255, 255, 1)",
    fontFamily: "Poppins",
    fontSize: "14px",
  },
  exportBtn: {
    backgroundColor: "#79A1FA",
    width: "160px",
    height: "58px",
  },
  tableHeads: {
    opacity: 1,
    fontWeight: 500,
    color: "rgba(178, 179, 189, 1)",
    fontFamily: "Poppins",
    fontSize: "12px",
  },
  bottom: {
    color: "#79A1FA",
  },
  tableDatas: {
    opacity: 1,
    fontWeight: 500,
    color: "rgba(17, 20, 45, 1)",
    fontFamily: "Poppins",
    fontSize: "14px",
  },
  loadMoreBtnTxt: {
    opacity: 1,
    fontWeight: 800,
    color: "rgba(255, 255, 255, 1)",
    fontFamily: "Poppins",
    fontSize: "14px",
  },
  loadMoreBtn: {
    width: "166px",
    height: "56px",
    backgroundColor: "#141618",
    borderRadius: "15px",
  },
  btnUserCancel: {
    backgroundColor: "#79A1FA",
    minWidth: "0px",
    borderRadius: "20px",
  },
  text4: {
    opacity: 1,
    fontWeight: 500,
    color: "rgba(178, 179, 189, 1)",
    fontFamily: "Poppins",
    fontSize: "12px",
  },
  DrawerBtnCreateUser: {
    backgroundColor: "#79A1FA",
    height: "50px",
  },
  text5: {
    opacity: 1,
    fontWeight: 500,
    color: "#FFFFFF",
    fontFamily: "Poppins",
    fontSize: "15px",
  },
  backdrop: {
    zIndex: 9999,
    color: "#fff",
  },
  text6: {
    opacity: 1,
    fontWeight: 500,
    color: "rgba(17, 20, 45, 1)",
    fontFamily: "Poppins",
    fontSize: "15px",
  },
  text7: {
    paddingLeft: "5px",
    textDecoration: "underline",
    color: "#91B2FD",
    fontFamily: "Poppins",
    fontSize: "14px",
    fontWeight: 500,
  },
  menuGridRoot: {
    width: "auto",
    height: "auto",
    maxWidth: "200px",
  },
  formControl: {
    width: "100%",
  },
  text8: {
    fontFamily: "Poppins",
    fontSize: "25px",
    fontWeight: 800,
    color: "rgba(17, 20, 45, 1)",
  },
  deleteCancelBtn: {
    backgroundColor: "#909090",
    width: "100px",
    height: "25px",
  },
  deleteDeleteBtn: {
    backgroundColor: "#F54E4F",
    width: "100px",
    height: "25px",
  },
  text9: {
    fontFamily: "Poppins",
    color: "#FFFFFF",
    fontSize: "13px",
    fontWeight: 600,
  },
  text10: {
    fontFamily: "Poppins",
    fontSize: "13px",
    color: "#FFFFFF",
    fontWeight: 600,
  },
  text11: {
    fontFamily: "Poppins",
    fontSize: "14px",
    color: "#909090",
    fontWeight: 700,
  },
  editConfirmBtn: {
    backgroundColor: "#79A1FA",
    width: "100px",
    height: "25px",
  },
});

// Customizable Area End

export default withStyles(styles(), { withTheme: true })(UserManagement);
