//@ts-nocheck
import React from 'react'
//Customizable Area Start
import {
  Grid,
  Container,
  Typography,
  Card,
  CardContent,
  Box,
  Tabs,
  Theme,
  Tab,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableFooter,
  TablePagination,
  Paper,
  LinearProgress,
  Select,
  MenuItem,
  FormControl,
  TextField,
  Modal,
  Backdrop,
  IconButton
} from "@material-ui/core";
// import FileUploadIcon from '@mui/icons-material/FileUpload';
import { withStyles } from "@material-ui/styles"
import DashboardController, { Props } from "./DashboardController";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
} from 'chart.js';
import { Doughnut, Line } from 'react-chartjs-2';
import { getformatedCurrentDate, noDataFound, pageLoader, skipped, down } from './utility';
import mqtt from 'mqtt';
import TimeCounter from './timeCounter';
import TargetSettingsForm from './TargetSettingsForm.web';
import OtpInput from "react-otp-input";
import CircularProgress from '@material-ui/core/CircularProgress';

ChartJS.register(
  ArcElement,
  Tooltip,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const modalStyle = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

const modalFullStyle = {
  position: 'absolute' as 'absolute',
  top: '10%',
  left: '20%',
  // transform: 'translate(-50%, -50%)',
  width: '60%',
  height: "80%",
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};
const backgroundImage = require("../assets/d556836cdbf3f98427e2ff9a6d3a66e43cc7b98f.png")
const exportIcon = require("../assets/export.svg");
const cleanDataIcon = require("../assets/cleaning.svg");
const calibrateIcon = require("../assets/calibrate.svg");
//Customizable Area End

export class DeviceInfo extends DashboardController {
  constructor(props: Props) {
    super(props);
    //Customizable Area Start
    // this.state = {
    //   anchorEl: "",
    //   deviceData: { labels: [], datasets: [] },
    //   deviceInfo: {}
    // };
    this.updateDeviceInformation("");
    this.getSelectedFlowData("");
    this.getCounterDetails('');
    this.getDeviceFilteredReports('default');
    this.getTargetSettings()
    //Customizable Area End
  }

  render() {

    const { navigation, classes } = this.props;

    //Customizable Area Start
    const lineOptions = {
      responsive: true,
      plugins: {
        legend: {
          position: 'top' as const,
          display: false
        },
        // title: {
        //   display: true,
        //   text: 'Chart.js Line Chart',
        // },
      },
    };
    const highestValue = this.state.chartFlowData.length>2 ?this.state.chartFlowData.reduce((acc:any, val:any) => acc > val ? acc : val) : 0;


    const labels = this.state.chartLabels;
    const lineData = {
      labels,
      datasets: [
        {
          label: 'Dataset 1',
          data: this.state.chartFlowData,
          borderColor: 'rgb(255, 99, 132)',
          backgroundColor: 'rgba(255, 9, 132, 0.5)',
          segment: {
            borderColor: (ctx: any) => skipped(ctx, 'rgb(0,0,0,0.2)') || down(ctx, 'rgb(192,75,75)'),
            borderDash: (ctx: any) => skipped(ctx, [6, 6]),
          },
          spanGaps: true,
          tension: 0.3
        },
        {
          label: 'Min Flow',
          fill: false,
          radius: 0,
          borderColor: 'rgb(0, 0, 0)',
          backgroundColor: 'rgba(0,0, 0, 0)',
          borderDash: [8, 5],
          data: this.state.minArray.fill(this.state.targetData.min_flow)
          
        },
        {
          label: 'Max Flow',
          fill: false,
          radius: 0,
          borderColor: 'rgb(0, 0, 0)',
          backgroundColor: 'rgba(0,0, 0, 0)',
          borderDash: [8, 5],
          data: this.state.maxArray.fill(this.state.targetData.max_flow)
        }
      ],
    };


    const rows = [
      { date: '11/10/2021', scale: 'Orange Weighing Scale #1', start_time: '00:00:17', end_time: '24:00:45', acc_weight: 6543.98, downtime: '-', empty_belt: '-', under_range: '-', opp_range: 19.45, over_range: 3.85, active_time: 23.10 }
    ];

    const subDevice = this.state.deviceMqttData;
    const deviceFlow =  (subDevice?.Flow && Math.sign(subDevice?.Flow) === 1) ? subDevice?.Flow : '0.00';
    const deviceSpeed = (subDevice?.Speed && Math.sign(subDevice?.Speed) === 1)? subDevice?.Speed : '0.00';
    const deviceLoad = (subDevice?.Load && Math.sign(subDevice?.Load) === 1 && subDevice?.Load < 1000)? subDevice?.Load :'0.00' ;
    const deviceTotal = subDevice?.Total;
    const disconnected = subDevice?.stat == 21 ? true : false;


    const clickLabel = {
      id: 'clickLabel',

      afterDraw: (chart: any, args: any, options: any) => {

        const { ctx, chartArea: { width, height, top } } = chart;
        ctx.save();
        ctx.font = 'bolder 40px Arial';
        ctx.fillStyle = "blue";
        ctx.textAlign = 'center';
        ctx.fillText(`${this.state.prdPercentage}%`, width / 2, height / 2 + 20)
       

      }
    }

    const config = {
      type: 'doughnut',
      data: this.state.deviceData.chartData,
      options: {
        cutout: '78%',
        plugins: { legend: { display: false } },
        onClick: (chart: any) => { chart.draw() },
      },
      plugins: [clickLabel]
    };
    const flowPercentage = deviceFlow && this.state.targetData?.max_flow ? ((deviceFlow / this.state.targetData?.max_flow) * 100) : 0;
    const speedPercentage = deviceFlow && this.state.targetData?.max_speed ? ((deviceSpeed / this.state.targetData?.max_speed) * 100) : 0;
    const loadPercentage = deviceFlow && this.state.targetData?.max_load ? ((deviceLoad / this.state.targetData?.max_load) * 100) : 0;
    const totalPercentage = deviceTotal && this.state.prdPercentage ? this.state.prdPercentage <= 100 ? this.state.prdPercentage : 100 : 0;
    return (
      <>
        <div className={classes.main}>
          {this.state.showLoader ? <Backdrop className={classes.backdrop} open={this.state.showLoader} onClick={this.handleBackDrop}>
            <CircularProgress color="inherit" />
          </Backdrop> : ""}

          <Container maxWidth="xl">
            <Grid container>
              <Grid item xs={12}>
                <Grid container>
                  <Grid item xs={3} className={classes.cardPrarent}>
                    <Card className={classes.card}>
                      <CardContent>
                        <Typography gutterBottom variant="h5" component="div">
                          Production
                        </Typography>
                        <Grid container>
                          <Grid item xs={12} className={classes.prodGraph}>
                            <Doughnut id="doughnutGraph" {...config} />
                          </Grid>
                          <Grid item xs={12} style={{ textAlign: "center" }} >
                            {disconnected ? <Typography className={classes.disconnected}>
                              Disconnected
                            </Typography> : deviceTotal ? <Box
                              className={classes.deviceLoadShow}
                            >
                              <Typography className={classes.deviceLoadText} style={css.fontBold}>
                                Total
                              </Typography>
                              <Typography className={classes.deviceLoadval} style={css.fontBold}>
                                {deviceTotal + ' t'}
                              </Typography>
                              <Typography className={classes.deviceLoadText} style={css.fontBold}>
                                Production in Tons
                              </Typography>
                              <LinearProgress variant="determinate" color='primary' value={totalPercentage} />
                            </Box> : ""}
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>

                  <Grid item xs={5} className={classes.cardPrarent}>

                    <Card className={classes.card}>
                      <CardContent className={classes.pZero}>
                        <Grid container className={classes.containerGrid}>
                          <Grid item xs={8}>
                            <Typography style={css.fontBold} gutterBottom variant="h5" component="div">
                              Flow Rate in TPH
                            </Typography>
                          </Grid>
                          <Grid item xs={4}>
                            <FormControl fullWidth>
                              <Select
                                id="deviceInfoMonth"
                                value={this.state.selectedFlowOption}
                                className={classes.deviceInfoMonthSelect}
                                disableUnderline={true}
                                onChange={this.handleSelectedFlowOption}
                                inputProps={{
                                  classes: {
                                    icon: classes.selectIcon,
                                  },
                                }}
                              >
                                <MenuItem value={"1 hour"}>1 hour</MenuItem>
                                <MenuItem value={"6 hour"}>6 hour</MenuItem>
                                <MenuItem value={"12 hour"}> 12 hour</MenuItem>
                                <MenuItem value={"1 day"}>1 day</MenuItem>
                                <MenuItem value={"7 day"}>7 day</MenuItem>
                                <MenuItem value={"30 day"}>30 day( Max )</MenuItem>

                                {/* <MenuItem value={"last Month"}>Last Month</MenuItem> */}
                              </Select>
                            </FormControl>
                          </Grid>
                        </Grid>

                        <Grid container className={classes.containerGrid}>
                          <Grid item xs={12} className={classes.prodGraph}>
                            {this.state.lineChartLoader ? pageLoader() : 
                            <Line options={lineOptions} data={lineData}
                            // options={{ plugins: { legend: { display: false } } }} 
                            // options={{ plugins: { tension } }} 
                            />}
                          </Grid>
                        </Grid>

                        <Grid item xs={12} >
                          <Typography id='fullScreenTPHModal' onClick={this.showFullModalForFlowRate} style={{ ...css.fontBold, color: '#79A1FA', textAlign: 'end', cursor: 'pointer' }} gutterBottom component="div">
                            See in Full screen
                          </Typography>
                        </Grid>

                        <Grid container>
                          <Modal
                            id="flowTPHGraphModal"
                            open={this.state?.showFlowTPHGraph || false}
                            onClose={() => this.setState({ showFlowTPHGraph: false, showLoaderModal: true })}
                            BackdropComponent={Backdrop}
                            BackdropProps={{
                              timeout: 500,
                            }}
                          >
                            <Box sx={modalFullStyle} >
                              <Grid container className={classes.containerGrid}>
                                <Grid item xs={11}>
                                  <Typography style={css.fontBold} gutterBottom variant="h5" component="div">
                                    Flow Rate in TPH
                                  </Typography>
                                </Grid>
                               
                                <Grid item xs={1} style={{ textAlign: "end" }}>
                                  <Button
                                    id="cancelFlowTPHGraph"
                                    variant="contained"
                                    className={classes.btnUserCancel}
                                    onClick={() => this.setState({ showFlowTPHGraph: false, showLoaderModal: true })}
                                  >
                                    <span style={{ color: "#FFFFFF" }}>x</span>
                                  </Button>
                                </Grid>
                                <Grid item xs={12} style={{ margin: "auto" }}>
                                  {this.state.showLoaderModal ? pageLoader() : <Line options={lineOptions} data={this.state.chartFlowDataModal} />}
                                </Grid>
                              </Grid>
                            </Box>
                          </Modal>
                        </Grid>
                        <Grid container>
                          <Grid item xs={4}>
                            <Box
                              className={classes.deviceLoadShow}
                            >
                              <Typography className={classes.deviceLoadText} style={css.fontBold}>
                                Flow
                              </Typography>
                              <Typography className={classes.deviceLoadval} style={css.fontBold}>
                                {deviceFlow}
                              </Typography>
                              <Typography className={classes.deviceLoadText} style={css.fontBold}>
                                TPH
                              </Typography>
                              <LinearProgress variant="determinate" color='primary' value={flowPercentage <= 100 ? flowPercentage : 100} />
                            </Box>
                          </Grid>
                          <Grid item xs={4}>
                            <Box
                              className={classes.deviceLoadShow}
                            >
                              <Typography className={classes.deviceLoadText} style={css.fontBold}>
                                Speed
                              </Typography>
                              <Typography className={classes.deviceLoadval} style={css.fontBold}>
                                {deviceSpeed}
                              </Typography>
                              <Typography className={classes.deviceLoadText} style={css.fontBold}>
                                M/S
                              </Typography>
                              <LinearProgress variant="determinate" color='primary' value={speedPercentage <= 100 ? speedPercentage : 100} />
                            </Box>
                          </Grid>

                          <Grid item xs={4}>
                            <Box className={classes.deviceLoadShow}>
                              <Typography className={classes.deviceLoadText} style={css.fontBold}>
                                Load
                              </Typography>
                              <Typography className={classes.deviceLoadval} style={css.fontBold}>
                                {deviceLoad}
                              </Typography>
                              <Typography className={classes.deviceLoadText} style={css.fontBold}>
                                KG/M
                              </Typography>
                              <LinearProgress variant="determinate" color='primary' value={loadPercentage <= 100 ? loadPercentage : 100} />
                            </Box>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>

                  <Grid item xs={4} className={classes.cardPrarent}>
                    <Box
                      className={classes.logsDiv}
                    >
                      <TimeCounter classes={classes} type="start" timeString={this.state.deviceCounter?.run_time} />
                    </Box>

                    <Box
                      className={classes.logsDiv + ' ' + classes.blueColor}
                    >
                      <TimeCounter classes={classes} type="down" timeString={this.state.deviceCounter?.down_time} />
                    </Box>

                    <Box
                      className={classes.logsDiv + ' ' + classes.darkGreyColor}
                    >
                      <TimeCounter classes={classes} type="idle" timeString={this.state.deviceCounter?.idle_time} />
                    </Box>
                  </Grid>

                  <Modal
                    id = "clearModal"
                    open={this.state.showClearModal}
                    onClose={() => this.setState({ showClearModal: false })}
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                      timeout: 500,
                    }}
                  >
                    <Box sx={modalStyle} >
                      <Grid container>
                        <Grid item xs={12}>
                          <Typography variant="body2" className={classes.text3}>
                            OTP
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography variant="body2" className={classes.text4}>
                            Enter OTP we have sent you over your Email ID.
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <OtpInput
                            value={this.state.otpObj.otp}
                            onChange={this.handleOtpChange}
                            numInputs={6}
                            inputStyle={classes.otpInput}
                          />
                          {this.state.otpObj.otpErrorMessage && (
                            <span className={classes.otpError}>
                              {this.state.otpObj.otpErrorMessage}
                            </span>
                          )}
                        </Grid>
                        <Grid item xs={12}>
                          <Button
                            variant="contained"
                            fullWidth
                            disableElevation
                            className={classes.btnRoot}
                            onClick={this.handleOtpSubmit}
                          >
                            <span className={classes.text5}>Submit</span>
                          </Button>
                        </Grid>

                      </Grid>
                    </Box>
                  </Modal>

                  <Grid container className={classes.cardPrarent}>
                    <Grid item xs={3}>
                      <Card onClick={() => this.clearData('clearData')} style={{ marginBottom: "5%", cursor: 'pointer', ...css.postionRel }} id="clearData" className={classes.card2}>
                        <CardContent>
                          <img style={css.postionAbs} src={cleanDataIcon} width="50%" />
                          <Typography gutterBottom variant="h5" component="div">
                            Clear Data
                          </Typography>
                        </CardContent>
                      </Card>
                      <Card onClick={() => this.clearData('calibrate')} id="calibrateData" style={{ cursor: 'pointer', ...css.postionRel }} className={classes.card2}>
                        <CardContent>
                          <img style={css.postionAbs} src={calibrateIcon} width="50%" />
                          <Typography gutterBottom variant="h5" component="div">
                            Zero Calibrate
                          </Typography>
                        </CardContent>
                      </Card>
                    </Grid>
                    <Grid item xs={9}>
                      <TargetSettingsForm
                        navigation={undefined}
                        id={""}
                      />
                    </Grid>
                  </Grid>

                  <Grid item xs={12} className={classes.cardPrarent}>
                    <Card className={classes.card}>
                      <CardContent>
                        <Grid container>
                          <Grid item xs={12} className={classes.prodGraph}>
                          
                            <div
                              role="tabpanel"
                              id={`simple-tabpanel-0`}
                              aria-labelledby={`simple-tab-0`}
                            >
                              <Box sx={{ p: 0 }}>
                                  <Grid container style={{ paddingBottom: 15, justifyContent: 'space-between' }} >
                                    <Grid item xs={8}>
                                      <Typography gutterBottom variant="h6" component="div">
                                        Reports
                                      </Typography>
                                      <Grid item xs={12}>
                                        <Grid container>
                                          <Grid item className={classes.fieldContainer}>
                                            <TextField
                                              id='startDateFilter'
                                              fullWidth
                                              variant="filled"
                                              label="Start date"
                                              type="date"
                                              name="start_date"
                                              InputLabelProps={{
                                                shrink: true
                                              }}
                                              InputProps={{
                                                classes: { root: classes.inputWhite },
                                                inputProps: { max: getformatedCurrentDate('') }
                                              }}
                                              error={!this.state.filterObj.startDateValid}
                                              helperText={
                                                <span style={{ fontSize: "16px", color: 'red' }}>
                                                  {this.state.filterObj.startDateMsg}
                                                </span>
                                              }
                                              size="small"
                                              value={this.state.filterObj.start_date}
                                              onChange={this.filterFieldChange}
                                            />
                                          </Grid>
                                          <Grid item className={classes.fieldContainer}>
                                            <FormControl fullWidth>
                                              <TextField
                                                id='end_date'
                                                fullWidth
                                                variant="filled"
                                                label=" End date"
                                                type="date"
                                                name="end_date"
                                                InputLabelProps={{
                                                  shrink: true,
                                                }}
                                                InputProps={{
                                                  classes: { root: classes.inputWhite },
                                                  inputProps: { max: getformatedCurrentDate('') }
                                                }}
                                                error={!this.state.filterObj.endDateValid}
                                                helperText={
                                                  <span style={{ fontSize: "16px", color: 'red' }}>
                                                    {this.state.filterObj.endDateMsg}
                                                  </span>
                                                }
                                                value={this.state.filterObj.end_date}
                                                onChange={this.filterFieldChange}
                                                size="small"
                                              />
                                            </FormControl>
                                          </Grid>

                                          <Grid item style={{ marginTop: "auto" }}>
                                            <Button
                                              variant="contained"
                                              onClick={this.validateDeviceFilterFields}
                                              size="small"
                                              style={{ marginRight: '5px' }}
                                              id="filterData"
                                            >
                                              Filter
                                            </Button>

                                            <Button
                                              variant="contained"
                                              onClick={this.resetFilter}
                                              size="small"
                                              id="resetData"
                                            >
                                              Reset
                                            </Button>

                                          </Grid>
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                    <Grid style={{ marginTop: 'auto' }}>
                                      <Button
                                        className={classes.exportBtn}
                                        color="primary"
                                        variant="contained"
                                        startIcon={<img src={exportIcon} />}
                                        onClick={this.exportPDFData}
                                        disabled={this.state.deviceReportsList.data.length > 0 ? false : true}
                                      >
                                        Export PDF
                                      </Button>
                                      <Button
                                        className={classes.exportBtn}
                                        color="primary"
                                        variant="contained"
                                        startIcon={<img src={exportIcon} />}
                                        onClick={this.exportCSVData}
                                        disabled={this.state.deviceReportsList.data.length > 0 ? false : true}
                                      >
                                        Export CSV
                                      </Button>
                                    </Grid>
                                  </Grid>
                                  {this.state.deviceReportsList.loader ? pageLoader() : this.state.deviceReportsList.data.length > 0 ? <TableContainer component={Paper}>
                                    <Table aria-label="simple table">
                                      <TableHead>
                                        <TableRow>
                                          <TableCell>Date</TableCell>
                                          <TableCell>Start Time</TableCell>
                                          <TableCell>End Time</TableCell>
                                          <TableCell>Run Time (Hours)</TableCell>
                                          <TableCell>Idle Time (Hours)</TableCell>
                                          <TableCell>Down Time (Hours)</TableCell>
                                          <TableCell>Average Flow (TPH)</TableCell>
                                          <TableCell>Average Speed</TableCell>
                                          <TableCell>Average Load</TableCell>
                                          <TableCell>Production in Tons</TableCell>
                                        </TableRow>
                                      </TableHead>
                                      <TableBody>
                                        {this.state.deviceReportsList.data.map((item: any, index: number) => (
                                          <TableRow
                                            key={`deviceDetailReports${index}`}
                                          >
                                            <TableCell component="th" scope="row">{item.date}</TableCell>
                                            <TableCell align="center">{item.start_time}</TableCell>
                                            <TableCell align="center">{item.end_time}</TableCell>
                                            <TableCell align="center">{item.run_time}</TableCell>
                                            <TableCell align="center">{item.idle_time}</TableCell>
                                            <TableCell align="center">{item.down_time}</TableCell>
                                            <TableCell align="center">{item.avg_flow}</TableCell>
                                            <TableCell align="center">{item.avg_speed}</TableCell>
                                            <TableCell align="center">{item.avg_load}</TableCell>
                                            <TableCell align="center">{item.weight}</TableCell>
                                          </TableRow>
                                        ))}
                                      </TableBody>
                                    </Table>
                                  </TableContainer> : noDataFound()}
                                  <Grid item xs={12} style={{ paddingTop: '5px', textAlign: 'center' }}>
                                    
                                    {this.state.deviceReportsList.loadMore ? <Button
                                      className={classes.nextBtn}
                                      variant="contained"
                                      disabled={true}
                                    >
                                      Loading...
                                    </Button> : (this.state.deviceReportsList.page_count > 1 && this.state.deviceReportsList.current_page < this.state.deviceReportsList.page_count) && <Button
                                      id="loadMoreButton"
                                      className={classes.nextBtn}
                                      variant="contained"
                                      onClick={() => this.reportsNextPage('next')}
                                    >
                                      Load More
                                    </Button>
                                    }

                                  </Grid>
                                </Box>
                            
                            </div>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Container>
        </div>
      </>
    )

    // Customizable Area End
  }
}

// Customizable Area Start
export const styles = (theme: Theme) => ({
  cardPrarent: {
    padding: '10px',
  },
  card: {
    boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
    borderRadius: 20,
    padding: "20px",
    height: '100%'
  },
  card2: {
    ['& img']: {
      top: 0,
      right: 0
    },
    ['& div']: {
      marginTop: 'auto',
    },
    display: 'flex',
    boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
    borderTopRightRadius: 250,
    overflow: 'unset',
    borderRadius: 40,
    marginRight: 20,
    height: '48%'
  },
  timeCounter: {
    padding: "20px",
  },
  logsDiv: {
    backgroundColor: 'green',
    color: 'white',
    padding: '20px',
    marginBottom: '20px',
    borderRadius: '15px'
  },
  blueColor: {
    backgroundColor: '#79A1FA',
  },
  darkGreyColor: {
    backgroundColor: 'darkgrey',
  },
  mediumText: {
    // color: 'grey',
    fontSize: '25px'
  },
  mediumText1: {
    fontWeight: 600
  },
  exportBtn: {
    margin: '0px 5px',
    padding: '10px 10px',
    backgroundColor: "#7AA2FA!important",
    borderRadius: 10,
    fontWeight: 600
  },
  pageBtnActive: {

    backgroundColor: "#7AA2FA!important",
    color: 'white',
    borderRadius: 15,
    fontWeight: 600,
    margin: '5px'
  },
  pageBtn: {
    borderRadius: 15,
    fontWeight: 600,
    margin: '5px'
  },
  nextBtn: {
    backgroundColor: 'black',
    color: 'white',
    borderRadius: 15,
    fontWeight: 600,
    margin: '5px'
  },
  font600: {
    fontWeight: 600
  },
  deviceLoadShow: {
    padding: '10px 20px'
  },
  deviceLoadText: {
    fontSize: "12px",
    color: "gray"
  },
  deviceLoadval: {
    fontSize: "20px",
  },
  deviceInfoMonthSelect: {
    backgroundColor: '#79A1FA',
    color: 'white',
    padding: "0px 15px",
    borderRadius: 5,
    marginBottom: "10px"
  },
  selectIcon: {
    color: 'white'
  },
  containerGrid: {
    padding: "5px 10px"
  },
  pZero: {
    padding: '0!important'
  },
  otpInput: {
    /* width: 100% !important; */
    fontFamily: "Open sans",
    fontWeight: 600,
    fontSize: "24px",
    height: "auto",
    margin: "10px 5px",
    borderRadius: "8.8px",
    padding: "20px 10px 10px",
    border: "1px solid #CFCFCF",
    backgroundColor: "#F1F1F1",
    width: '45px!important'
  },
  btnRoot: {
    height: "50px",
    borderRadius: "15px !important",
    backgroundColor: "#79A1FA",
  },
  text3: {
    opacity: 1,
    backgroundColor: "transparent",
    fontWeight: 800,
    color: "#79A1FA",
    fontFamily: "AppleColorEmoji",
    fontSize: "40px",
  },
  text4: {
    color: "#B9BAC3",
    opacity: 1,
    backgroundColor: "transparent",
    fontWeight: 600,
    fontFamily: "Poppins",
    fontSize: "14px",
  },
  text5: {
    color: "#FFFFFF",
    opacity: 1,
    backgroundColor: "transparent",
    fontFamily: "Poppins",
    fontSize: "14px",
  },
  otpError: {
    color: "red !important",
    paddingTop: "10px",
  },
  backdrop: {
    zIndex: 1500,
    color: '#fff',
  },
  fieldContainer: {
    paddingRight: "15px"
  },
  inputWhite: {
    backgroundColor: "white",
  },
  disconnected: {
    color: 'red',
    fontSize: '20px',
    marginBottom: "10px",
    fontWeight: 700,
  },
  quantity: {
    color: '#79A1FA',
    fontSize: '40px',
    fontWeight: 700,
  },
  btnUserCancel: {
    backgroundColor: "#79A1FA",
    minWidth: "0px",
    borderRadius: "20px",
  }
});

const css: { [key: string]: React.CSSProperties } = {
  postionRel: {
    position: "relative",
  },
  postionAbs: {
    position: "absolute",
  },
  fontBold: {
    fontWeight: "bold"
  }
};
// Customizable Area End


export default withStyles(styles, { withTheme: true })(DeviceInfo);
