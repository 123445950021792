//@ts-nocheck
import React, { Component } from "react";
import {
  Grid,
  Container,
  Theme,
  Button,
  Typography,
  TextField,
  Drawer,
  CssBaseline,
  AppBar,
  Toolbar,
  List,
  Divider,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import MuiListItem from "@material-ui/core/ListItem";
import DashboardController, { Props } from "./DashboardController";

import DashboardInner from "./DashboardInner.web";
import Settings2 from "../../Settings2/src/Settings2.web";
import Header from "./Header.web";
import DeviceInfo from "./DeviceInfo.web";
import AdHocReporting from "../../AdHocReporting/src/AdHocReporting.web";
import UserManagement from "../../user-profile-basic/src/UserManagement.web";
import ShiftManagement from "../../user-profile-basic/src/ShiftManagement.web";
import Notifications from "../../Notifications/src/Notifications.web";
import { getUserType } from "./utility";

const EsskaySystemsLogo = require("../assets/EsskaySystemsLogo.png");
const dashboardIcon = require("../assets/dashboard.png");
const reportIcon = require("../assets/report-1.png");
const settingsIcon = require("../assets/settings-1.png");
const backgroundImage = require("../assets/background.png");
const userIcon = require("../assets/userIcon.png");
const userManagmentIcon = require("../assets/dashboardUserManagment.png");
const shiftSettingsIcon = require("../assets/dashboardShiftSettings.png");

const drawerWidth = 240;

const ListItem = withStyles({
  root: {
    "&$selected": {
      backgroundColor: "#79A1FA",

      color: "white",
      opacity: 1,
    },
    "&:hover": {
      backgroundColor: "#79A1FA",
      color: "white",
    },
    borderRadius: "10px",
    marginBottom: "10px",
    color: "#5b6b96",
  } as const,
  selected: {},
})(MuiListItem);

export class Dashboard extends DashboardController {
  constructor(props: Props) {
    super(props);
    //Customizable Area Start
    this.saveFireBase();
    this.getNotificationMsg();
    this.getNotificationsUnreadCount();
    //Customizable Area End
  }

  render() {
    // Customizable Area Start
    const { navigation, classes } = this.props;

    let userInfo: any = localStorage.getItem('userInfo');
    userInfo = JSON.parse(userInfo);

    return (
      <>
        <Router>
          <div className={classes.root}>
            <CssBaseline />
            <AppBar position="fixed" className={classes.appBar}>
              <Header navigation={undefined} id={""} notificationCount={this.state.notificationCount} />
            </AppBar>
            <Drawer
              className={classes.drawer}
              variant="permanent"
              classes={{
                paper: classes.drawerPaper,
              }}
              anchor="left"
            >
              {/* <div className={classes.toolbar} /> */}
              <div className={classes.esskayLogoMain}>
                <img style={{ width:"100%" }} src={EsskaySystemsLogo} />
              </div>
              {/* <div className={classes.toolbar} /> */}
              <Typography variant="body2" className={classes.adminText}>
                Tools
              </Typography>
              <List>
                <Link
                  to="/Dashboard"
                  style={{
                    textDecoration: "none",
                    color: "black",
                  }}
                >
                  <ListItem
                    button
                    id="go_to_dashboard"
                    key="Dashboard"
                    selected={
                      "/Dashboard".toLowerCase() === this.state.selectedPath
                    }
                    onClick={() =>
                      this.setState({
                        selectedPath: "/Dashboard".toLowerCase(),
                      })
                    }
                  >
                    <ListItemIcon className={classes.iconsRoot}>
                      <img src={dashboardIcon} />
                    </ListItemIcon>
                    <ListItemText primary={"Dashboard"} />
                  </ListItem>
                </Link>
                <Link
                  to="/Dashboard/AdHocReporting"
                  style={{
                    textDecoration: "none",
                    color: "black",
                  }}
                >
                  <ListItem
                    button
                    id="go_to_adhocreporting"
                    key="Reports"
                    selected={
                      "/Dashboard/AdHocReporting".toLowerCase() ===
                      this.state.selectedPath
                    }
                    onClick={() =>
                      this.setState({
                        selectedPath: "/Dashboard/AdHocReporting".toLowerCase(),
                      })
                    }
                  >
                    <ListItemIcon className={classes.iconsRoot}>
                      <img src={reportIcon} />
                    </ListItemIcon>
                    <ListItemText primary={"Reports"} />
                  </ListItem>
                </Link>
                {getUserType() == "CustomerAdmin" && <Link
                  to="/Dashboard/ShiftManagement"
                  style={{
                    textDecoration: "none",
                    color: "black",
                  }}
                >
                  <ListItem
                    button
                    id="go_to_shiftmgmt"
                    key="Reports"
                    selected={
                      "/Dashboard/ShiftManagement".toLowerCase() ===
                      this.state.selectedPath
                    }
                    onClick={() =>
                      this.setState({
                        selectedPath: "/Dashboard/ShiftManagement".toLowerCase(),
                      })
                    }
                  >
                    <ListItemIcon className={classes.iconsRoot}>
                      <img src={shiftSettingsIcon}/>
                    </ListItemIcon>
                    <ListItemText primary={"Shift Settings"} />
                  </ListItem>
                </Link>}

                {getUserType() == "CustomerAdmin" && <Link
                  to="/Dashboard/UserManagement"
                  style={{
                    textDecoration: "none",
                    color: "black",
                  }}
                >
                  <ListItem
                    button
                    id="go_to_usermgmt"
                    key="UserManagement"
                    selected={
                      "/Dashboard/UserManagement".toLowerCase() ===
                      this.state.selectedPath
                    }
                    onClick={() =>
                      this.setState({
                        selectedPath: "/Dashboard/UserManagement".toLowerCase(),
                      })
                    }
                  >
                    <ListItemIcon className={classes.iconsRoot}>
                      <img src={userManagmentIcon}/>
                    </ListItemIcon>
                    <ListItemText primary={"User Management"} />
                  </ListItem>
                </Link>}
                <Link
                  to="/Dashboard/Settings2"
                  style={{
                    textDecoration: "none",
                    color: "black",
                  }}
                >
                  <ListItem
                    button
                    id="go_to_settings"
                    key="Settings"
                    selected={
                      "/Dashboard/Settings2".toLowerCase() ===
                      this.state.selectedPath
                    }
                    onClick={() =>
                      this.setState({
                        selectedPath: "/Dashboard/Settings2".toLowerCase(),
                      })
                    }
                  >
                    <ListItemIcon className={classes.iconsRoot}>
                      <img src={settingsIcon} />
                    </ListItemIcon>
                    <ListItemText primary={"Settings"} />
                  </ListItem>
                </Link>
              </List>
              <Grid container className={classes.footText} style={css.footText}>
                <Grid item>
                  <img className={classes.userIcon} src={this.state.userImg ? this.state.userImg : userIcon} width="40px" />
                </Grid>
                <Grid item style={{ marginLeft: "10px" }}>
                  <Typography variant="body2">{userInfo?.data?.attributes?.first_name + ' ' + userInfo?.data?.attributes?.last_name}</Typography>
                  <Typography variant="body2">{userInfo?.data?.attributes?.role}</Typography>
                </Grid>
              </Grid>
            </Drawer>

            <main className={classes.content}>
              <div className={classes.toolbar} />
              <Switch>
                <Route exact path="/Dashboard">
                  <DashboardInner navigation={undefined} id={""} />
                </Route>
                <Route path="/Dashboard/DeviceInfo/:imei/:sid">
                  <DeviceInfo navigation={undefined} id={""} />
                </Route>
                <Route exact path="/Dashboard/AdHocReporting">
                  <AdHocReporting navigation={undefined} id={""} />
                </Route>
                <Route exact path="/Dashboard/Settings2">
                  <Settings2 navigation={undefined} id={""} />
                </Route>
                <Route exact path="/Dashboard/UserManagement">
                  <UserManagement navigation={undefined} id={""} />
                </Route>
                <Route exact path="/Dashboard/ShiftManagement">
                  <ShiftManagement navigation={undefined} id={""} />
                </Route>
                <Route exact path="/Dashboard/Notifications">
                  <Notifications navigation={undefined} id={""} makeNotificationZero={() => { this.setState({ notificationCount: 0 }) }} />
                </Route>
              </Switch>
            </main>
          </div>
        </Router>
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const styles = (theme: Theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    boxShadow: "none",
    backgroundColor: "white",
    color: "black",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    padding: "10px",
    backgroundImage: `url(${backgroundImage})`,
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    backgroundColor: "white",
    paddingTop: theme.spacing(3),
  },
  esskayLogoMain: {
    paddingLeft: "10px",
  },
  adminText: {
    paddingLeft: "20px",
  },
  iconsRoot: {
    minWidth: "30px !important",
  },
  footText: {
    bottom: "20px",
    marginLeft: "2rem",
    width: 'unset',
  },
  userIcon: {
    height: "39px",
    width: "39px",
    borderRadius: "35px",
  },
});

const css: { [key: string]: React.CSSProperties } = {
  footText: {
    position: "fixed",
  },
};

// Customizable Area End

export default withStyles(styles, { withTheme: true })(Dashboard);
